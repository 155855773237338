import { render, staticRenderFns } from "./yibanli.vue?vue&type=template&id=7a5878da&scoped=true&"
import script from "./yibanli.vue?vue&type=script&lang=js&"
export * from "./yibanli.vue?vue&type=script&lang=js&"
import style0 from "./yibanli.vue?vue&type=style&index=0&id=7a5878da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a5878da",
  null
  
)

export default component.exports